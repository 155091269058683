
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    data: Array as PropType<{}[]>,
  },
  setup () {
    return {
    }
  }
});
